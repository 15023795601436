<template lang="pug">
  svg#iug_unwrap(width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M4.8011 6.87675L2.03695 9.63987V7.90541C2.03695 7.34015 1.58169 6.88489 1.01848 6.88489C0.455259 6.88489 0 7.34015 0 7.90541V12.0924C0 12.3745 0.115088 12.6311 0.302488 12.8165C0.487851 13.0019 0.74247 13.117 1.02459 13.117H5.21155C5.7768 13.117 6.23206 12.6617 6.23206 12.0985C6.23206 11.5332 5.7768 11.08 5.21155 11.08H3.47504L6.24224 8.31484C6.64047 7.91661 6.64251 7.27293 6.24224 6.87471C5.84198 6.47547 5.19932 6.4775 4.8011 6.87675Z')
    path(:fill='changeFill' d='M12.8166 0.300451C12.6312 0.115088 12.3766 0 12.0945 0H7.90752C7.34227 0 6.88701 0.455259 6.88701 1.01848C6.88701 1.58271 7.34227 2.03695 7.90752 2.03695H9.64199L6.87683 4.80212C6.4786 5.20034 6.47656 5.84402 6.87683 6.24224C7.27505 6.64251 7.91873 6.64047 8.31695 6.24224L11.0821 3.47708V5.21155C11.0821 5.7768 11.5374 6.23206 12.1006 6.23104C12.6638 6.23104 13.1191 5.77578 13.1191 5.21155V1.02459C13.1181 0.74247 13.003 0.485813 12.8166 0.300451Z')
</template>

<script>
export default {
  name: 'IUG_UnwrapSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#667b87',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>